import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

import { IconVariants, iconVariants } from '../IconClasses';

@Component({
  selector: 'ods-send-icon',
  standalone: true,
  imports: [NgClass],
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-primary', class)]"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M0 21.6693V0.335938L25.3333 11.0026L0 21.6693ZM2.66667 17.6693L18.4667 11.0026L2.66667 4.33594V9.0026L10.6667 11.0026L2.66667 13.0026V17.6693Z"
    />
  </svg>`,
})
export class SendIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = undefined;

  iconVariants = iconVariants;
  twMerge = twMerge;
}
