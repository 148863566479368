<a
  mat-button
  data-test-id="open-url-in-new-window"
  [href]="url"
  [target]="targetName"
  [attr.aria-label]="text"
  [matTooltip]="tooltip"
  [color]="'primary'"
  [title]="tooltip"
  class="button"
>
  <mat-icon>open_in_new</mat-icon>
  <span>{{ text }}</span>
</a>
