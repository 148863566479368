/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpErrorResponse } from '@angular/common/http';
import { isNil } from 'lodash-es';
import { MessageCode } from '../message-code';
import { ApiError, HttpError } from '../tech.model';

export function isApiError(value: any): boolean {
  return !isNil(value?.issues) && !isNil(value.issues[0]);
}

export function isServiceUnavailableMessageCode(error: ApiError): boolean {
  return error.issues[0].messageCode == MessageCode.SERVICE_UNAVAILABLE;
}

export function getApiErrorFromHttpErrorResponse(httpErrorResponse: HttpErrorResponse): ApiError {
  return httpErrorResponse?.error?.error;
}
