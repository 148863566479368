/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { add2000Years } from '@alfa-client/tech-shared';
import { Component, Input } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { isDate } from 'date-fns';
import { FormControlEditorAbstractComponent } from '../formcontrol-editor.abstract.component';

@Component({
  selector: 'ozgcloud-date-editor',
  templateUrl: './date-editor.component.html',
  styleUrls: ['./date-editor.component.scss'],
})
export class DateEditorComponent extends FormControlEditorAbstractComponent {
  @Input() label: string;
  @Input() required: boolean = false;

  inputEvent(event: MatDatepickerInputEvent<Date>): void {
    this.onChange(event.value);
  }

  onBlur(): void {
    if (!isDate(this.fieldControl.value)) {
      return;
    }

    const value: Date = add2000Years(this.fieldControl.value);

    this.fieldControl.patchValue(value);
    this.onChange(value);
  }
}
