/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { isNil } from 'lodash-es';
import { ApiError, InvalidParam, Issue, IssueParam } from '../tech.model';
import { isNotNil, replacePlaceholder } from '../tech.util';
import { VALIDATION_MESSAGES, ValidationMessageCode } from './tech.validation.messages';

export function isValidationError(issue: Issue): boolean {
  return issue.messageCode.includes('javax.validation.constraints');
}

export function setIssueValidationError(
  form: UntypedFormGroup,
  issue: Issue,
  pathPrefix?: string,
): void {
  const control: AbstractControl = getControlForIssue(form, issue, pathPrefix);

  control.setErrors({ [issue.messageCode]: issue });
  control.markAsTouched();
}

export function getControlForIssue(
  form: UntypedFormGroup,
  issue: Issue,
  pathPrefix?: string,
): AbstractControl {
  const fieldPath: string = getFieldPathWithoutPrefix(issue.field, pathPrefix);

  let curControl: AbstractControl = form;
  fieldPath
    .split('.')
    .forEach((field) => (curControl = (<UntypedFormGroup>curControl).controls[field]));

  return curControl;
}

export function getMessageForIssue(label: string, issue: Issue): string {
  let msg: string = VALIDATION_MESSAGES[issue.messageCode];

  if (isNil(msg)) {
    console.warn('No message for code ' + issue.messageCode + ' found.');
    return issue.messageCode;
  }

  msg = replacePlaceholder(msg, 'field', label);
  issue.parameters.forEach(
    (param: IssueParam) => (msg = replacePlaceholder(msg, param.name, param.value)),
  );
  return msg;
}

export function isValidationFieldFileSizeExceedError(error: any) {
  return getMessageCode(error) === ValidationMessageCode.VALIDATION_FIELD_FILE_SIZE_EXCEEDED;
}

export function getMessageCode(apiError: ApiError): string {
  return apiError.issues[0].messageCode;
}

export function setInvalidParamValidationError(
  form: UntypedFormGroup,
  invalidParam: InvalidParam,
  pathPrefix?: string,
): void {
  const control: AbstractControl = getControlForInvalidParam(form, invalidParam, pathPrefix);
  if (isNotNil(control)) {
    control.setErrors({
      [invalidParam.reason]: getMessageForInvalidParam(invalidParam, pathPrefix),
    });
    control.markAsTouched();
  }
}

export function getControlForInvalidParam(
  form: UntypedFormGroup,
  invalidParam: InvalidParam,
  pathPrefix?: string,
): AbstractControl {
  return form.get(getFieldPathWithoutPrefix(invalidParam.name, pathPrefix));
}

export function getMessageForInvalidParam(item: InvalidParam, pathPrefix: string): string {
  return replacePlaceholder(
    VALIDATION_MESSAGES[item.reason],
    'field',
    getFieldPathWithoutPrefix(item.name, pathPrefix),
  );
}

function getFieldPathWithoutPrefix(name: string, pathPrefix?: string): string {
  return pathPrefix ? name.substring(pathPrefix.length + 1) : name;
}
