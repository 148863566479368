/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ContentType,
  GetRequestOptions,
  HttpHeader,
  SkipInterceptor,
} from '@alfa-client/tech-shared';
import { Resource, ResourceFactory, ResourceUri, getUrl } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { BinaryFileLinkRel } from './binary-file.linkrel';
import { BinaryFileListResource, BinaryFileResource } from './binary-file.model';

@Injectable({ providedIn: 'root' })
export class BinaryFileRepository {
  constructor(
    private httpClient: HttpClient,
    private resourceFactory: ResourceFactory,
  ) {}

  public uploadFile(resource: Resource, linkRel: string, file: File): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);

    return this.httpClient.post(getUrl(resource, linkRel), formData, { observe: 'response' });
  }

  public download(fileResource: BinaryFileResource): Observable<Blob> {
    return this.doDownload(
      getUrl(fileResource, BinaryFileLinkRel.DOWNLOAD),
      this.buildRequestOptions(),
    );
  }

  buildRequestOptions(): GetRequestOptions {
    return this.buildBaseRequestOptions([ContentType.APPLICATION_ALL, ContentType.IMAGES_ALL]);
  }

  @SkipInterceptor()
  public downloadPdf(uri: ResourceUri): Observable<Blob> {
    return this.doDownload(uri, this.buildPdfRequestOptions());
  }

  private doDownload(uri: ResourceUri, requestOptions: GetRequestOptions): Observable<Blob> {
    return this.httpClient.get<Blob>(uri, requestOptions);
  }

  buildPdfRequestOptions(): GetRequestOptions {
    return this.buildBaseRequestOptions([
      ContentType.APPLICATION_PDF,
      ContentType.APPLICATION_JSON,
    ]);
  }

  buildBaseRequestOptions(contentTypes: ContentType[]): GetRequestOptions {
    let headers = new HttpHeaders();
    headers = headers.set(HttpHeader.ACCEPT, contentTypes);
    return { headers, responseType: 'blob' as 'json' };
  }

  public getFile(fileUri: ResourceUri): Observable<BinaryFileResource> {
    return this.resourceFactory.fromId(fileUri).get();
  }

  public getFiles(resource: Resource, linkRel: string): Observable<BinaryFileListResource> {
    return this.resourceFactory.from(resource).get(linkRel);
  }
}
