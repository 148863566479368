import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, ViewContainerRef } from '@angular/core';
import { isNil } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class OzgcloudDialogService {
  readonly WIZARD_DIALOG_CONFIG: DialogConfig = {
    width: '1000px',
    restoreFocus: false,
  };

  constructor(private dialog: Dialog) {}

  public openWizard<T, D>(component: ComponentType<T>, data?: D): DialogRef<T> {
    return this.openDialog<T>(
      component,
      this.buildDialogConfigWithData<D>(data, this.WIZARD_DIALOG_CONFIG),
    );
  }

  public open<T, D = unknown>(component: ComponentType<T>, data?: D): DialogRef<T> {
    return this.openDialog(component, this.buildDialogConfigWithData(data));
  }

  public openInCallingComponentContext<T, D = unknown>(
    component: ComponentType<T>,
    viewContainerRef: ViewContainerRef,
    data?: D,
  ): DialogRef<T> {
    return this.openDialog(component, this.buildDialogConfigWithData(data, { viewContainerRef }));
  }

  private buildDialogConfigWithData<D>(data: D, dialogConfig?: DialogConfig): DialogConfig | null {
    if (isNil(data)) {
      return dialogConfig;
    }
    return { ...dialogConfig, data };
  }

  private openDialog<T>(component: ComponentType<T>, dialogConfig?: DialogConfig): DialogRef<T> {
    return this.dialog.open<T>(component, dialogConfig);
  }

  public closeAll(): void {
    this.dialog.closeAll();
  }
}
