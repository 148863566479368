/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, Input, OnInit } from '@angular/core';
import { FormControlEditorAbstractComponent } from '../formcontrol-editor.abstract.component';
import { EnumEditorItem } from './enum-editor.model';

@Component({
  selector: 'ozgcloud-enum-editor',
  templateUrl: './enum-editor.component.html',
  styleUrls: ['./enum-editor.component.scss'],
})
export class EnumEditorComponent extends FormControlEditorAbstractComponent implements OnInit {
  @Input() label: string;
  @Input() itemList: EnumEditorItem[];
  @Input() defaultItem: EnumEditorItem;

  selectedItem: EnumEditorItem;

  override ngOnInit(): void {
    this.selectedItem = this.defaultItem;
  }

  setSelectedEnumItem(item: EnumEditorItem): void {
    this.selectedItem = item;
    this.writeValue(item.type);
  }
}
