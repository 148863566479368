import { VariantProps, cva } from 'class-variance-authority';

export const iconVariants = cva('', {
  variants: {
    size: {
      full: 'size-full',
      small: 'size-4',
      medium: 'size-6',
      large: 'size-8',
      'extra-large': 'size-10',
    },
  },
});

export type IconVariants = VariantProps<typeof iconVariants>;
