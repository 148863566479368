/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CreateCommand } from '@alfa-client/command-shared';
import { ListResource, StateResource } from '@alfa-client/tech-shared';
import { FixedDialogData } from '@alfa-client/ui';
import { Resource, ResourceUri } from '@ngxp/rest';
import { PostfachNachrichtMessageCode } from './postfach.message-code';

export interface PostfachMail {
  subject: string;
  createdAt: Date;
  direction: Direction;
  mailBody: string;
  replyOption: ReplyOption;
  sentAt: Date;
  sentSuccessful: boolean;
  messageCode: PostfachNachrichtMessageCode;
  attachments: ResourceUri[] | string;
}

export enum Direction {
  IN = 'IN',
  OUT = 'OUT',
}

export enum ReplyOption {
  POSSIBLE = 'POSSIBLE',
  MANDATORY = 'MANDATORY',
  FORBIDDEN = 'FORBIDDEN',
}

export enum PostfachOrder {
  SEND_POSTFACH_NACHRICHT = 'SEND_POSTFACH_NACHRICHT',
  RESEND_POSTFACH_MAIL = 'RESEND_POSTFACH_MAIL',
}

export interface CreatePostfachMailCommand extends CreateCommand {
  order: PostfachOrder;
}

export interface PostfachMailResource extends Resource, PostfachMail {}

export interface PostfachMailListResource extends ListResource {
  features: Features;
}

export enum PostfachNachrichtenCount {
  NONE,
  SINGLE,
  MULTIPLE,
}

export interface PostfachDialogComponent {}

//TODO Rename PostfachNachrichtDialogData
export interface PostfachMailFormDialogData extends FixedDialogData {
  empfaenger: string;
  postfachMailListStateResource?: StateResource<PostfachMailListResource>;
  postfachNachricht?: PostfachMailResource;
}

export interface Features {
  //TODO Rename to PostfachFeatures
  reply: boolean;
}
