<!--

    Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<mat-form-field [appearance]="appearance" [floatLabel]="autoFocus ? 'always' : 'auto'">
  <mat-label>{{ label }}</mat-label>

  <textarea
    #autosize="cdkTextareaAutosize"
    placeholder="{{ placeholder }}"
    matInput
    cdkTextareaAutosize
    [formControl]="fieldControl"
    [required]="required"
    [attr.data-test-id]="(label | convertForDataTest) + '-textarea-input'"
    (blur)="touch()"
  ></textarea>

  <mat-error>
    <ozgcloud-validation-error
      [issues]="issues"
      [label]="label"
      [attr.data-test-id]="(label | convertForDataTest) + '-textarea-error'"
    >
    </ozgcloud-validation-error>
  </mat-error>
</mat-form-field>
