/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { HttpBinaryFileInterceptor } from './interceptor/http-binary-file.interceptor';
import { HttpXsrfInterceptor } from './interceptor/http-xsrf.interceptor';
import { XhrInterceptor } from './interceptor/xhr.interceptor';
import { ConvertApiErrorToErrorMessagesPipe } from './pipe/convert-api-error-to-error-messages.pipe';
import { ConvertForDataTestPipe } from './pipe/convert-for-data-test.pipe';
import { ConvertToBooleanPipe } from './pipe/convert-to-boolean.pipe';
import { EnumToLabelPipe } from './pipe/enum-to-label.pipe';
import { FileSizePlainPipe } from './pipe/file-size-plain.pipe';
import { FileSizePipe } from './pipe/file-size.pipe';
import { FormatDateWithTimePipe } from './pipe/format-date-with-time.pipe';
import { FormatDateWithoutYearWithTimePipe } from './pipe/format-date-without-year-with-time.pipe';
import { FormatFullDatePipe } from './pipe/format-full-date.pipe';
import { FormatToPrettyDatePipe } from './pipe/format-to-pretty-date.pipe';
import { GetUrlPipe } from './pipe/get-url.pipe';
import { HasAnyLinkPipe } from './pipe/has-any-link.pipe';
import { HasLinkPipe } from './pipe/has-link.pipe';
import { NotHasLinkPipe } from './pipe/not-has-link.pipe';
import { ToEmbeddedResourcesPipe } from './pipe/to-embedded-resource.pipe';
import { ToResourceUriPipe } from './pipe/to-resource-uri.pipe';
import { ToTrafficLightTooltipPipe } from './pipe/to-traffic-light-tooltip.pipe';
import { ToTrafficLightPipe } from './pipe/to-traffic-light.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FormatToPrettyDatePipe,
    FormatFullDatePipe,
    EnumToLabelPipe,
    FormatDateWithTimePipe,
    FormatDateWithoutYearWithTimePipe,
    HasLinkPipe,
    HasAnyLinkPipe,
    NotHasLinkPipe,
    ToResourceUriPipe,
    ToTrafficLightPipe,
    ToTrafficLightTooltipPipe,
    ToEmbeddedResourcesPipe,
    ConvertForDataTestPipe,
    FileSizePipe,
    FileSizePlainPipe,
    GetUrlPipe,
    ConvertToBooleanPipe,
    ConvertApiErrorToErrorMessagesPipe,
  ],
  exports: [
    FormatToPrettyDatePipe,
    FormatFullDatePipe,
    EnumToLabelPipe,
    FormatDateWithTimePipe,
    FormatDateWithoutYearWithTimePipe,
    HasLinkPipe,
    HasAnyLinkPipe,
    NotHasLinkPipe,
    ToResourceUriPipe,
    ToTrafficLightPipe,
    ToTrafficLightTooltipPipe,
    ToEmbeddedResourcesPipe,
    ConvertForDataTestPipe,
    FileSizePipe,
    FileSizePlainPipe,
    GetUrlPipe,
    ConvertToBooleanPipe,
    ConvertApiErrorToErrorMessagesPipe,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XhrInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpXsrfInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpBinaryFileInterceptor,
      multi: true,
    },
  ],
})
export class TechSharedModule {
  public static injector: Injector;

  constructor(private injector: Injector) {
    TechSharedModule.injector = this.injector;
  }
}
