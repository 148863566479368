import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

import { IconVariants, iconVariants } from '../IconClasses';

@Component({
  selector: 'ods-close-icon',
  standalone: true,
  imports: [NgClass],
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-black', class)]"
    aria-hidden="true"
    viewBox="0 0 14 14"
    fill="inherit"
  >
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
    />
  </svg>`,
})
export class CloseIconComponent {
  @Input() size: IconVariants['size'] = 'small';
  @Input() class: string = undefined;

  iconVariants = iconVariants;
  twMerge = twMerge;
}
