import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

import { IconVariants, iconVariants } from '../IconClasses';

@Component({
  selector: 'ods-bescheid-generate-icon',
  standalone: true,
  imports: [NgClass],
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-pdf', class)]"
    aria-hidden="true"
    fill="none"
    viewBox="0 0 32 40"
  >
    <path
      d="M32 12 20 0H4C1.8 0 .02 1.8.02 4L0 36c0 2.2 1.78 4 3.98 4H28c2.2 0 4-1.8 4-4V12Zm-4 24H4V4h14v10h10v22Z"
    />
    <path d="M18.273 2.86H2.856v34.285h26.429V13.574H18.273V2.86Z" />
    <circle cx="16" cy="20" r="11.5" stroke="#fff" stroke-opacity=".3" stroke-width="3" />
    <path
      stroke="#fff"
      stroke-linecap="round"
      stroke-width="3"
      d="M25.94 25.782a11.5 11.5 0 0 1-15.811 4.106"
    />
    <path
      fill="#fff"
      d="M5.478 17.517 3.351 24H1.638l2.905-7.82h1.09l-.155 1.337ZM7.245 24l-2.132-6.483-.172-1.337h1.101L8.964 24H7.245Zm-.097-2.911v1.262h-4.13V21.09h4.13Zm6.763-4.91h1.606v5.168c0 .601-.13 1.108-.387 1.52a2.386 2.386 0 0 1-1.058.929c-.448.208-.963.311-1.547.311s-1.103-.103-1.558-.311a2.419 2.419 0 0 1-1.063-.93c-.254-.411-.382-.918-.382-1.52V16.18h1.612v5.167c0 .35.055.637.166.86.111.221.27.384.478.488.212.104.46.156.747.156.293 0 .542-.052.746-.156.208-.104.366-.267.473-.489.111-.222.167-.508.167-.86V16.18Zm6.429 0V24h-1.606v-7.82h1.606Zm2.406 0v1.263h-6.38V16.18h6.38Zm7.192 3.728v.37c0 .595-.08 1.129-.242 1.601a3.519 3.519 0 0 1-.682 1.209c-.293.33-.644.582-1.053.757a3.353 3.353 0 0 1-1.348.263c-.49 0-.94-.087-1.348-.263a3.021 3.021 0 0 1-1.053-.757 3.539 3.539 0 0 1-.692-1.209 4.945 4.945 0 0 1-.242-1.6v-.37c0-.599.08-1.132.242-1.601.16-.473.388-.876.682-1.209a2.992 2.992 0 0 1 1.052-.763 3.38 3.38 0 0 1 1.348-.263c.495 0 .944.088 1.349.263.408.176.759.43 1.052.763.298.333.527.736.688 1.209.165.469.247 1.002.247 1.6Zm-1.627.37v-.38c0-.416-.038-.781-.113-1.096a2.373 2.373 0 0 0-.333-.795 1.437 1.437 0 0 0-.537-.483 1.535 1.535 0 0 0-.726-.167c-.272 0-.513.055-.725.166a1.45 1.45 0 0 0-.531.484c-.143.215-.253.48-.328.795-.075.315-.113.68-.113 1.096v.38c0 .413.038.778.113 1.096.075.316.186.582.333.8.147.216.326.379.537.49.211.11.453.166.725.166.272 0 .514-.055.725-.166.212-.111.389-.274.532-.49.143-.218.253-.484.328-.8a4.8 4.8 0 0 0 .113-1.095Z"
    />
    <path stroke="#FEFEFE" stroke-linecap="round" stroke-width="3" d="m10 30.022 1.287 4.773" />
  </svg>`,
})
export class BescheidGenerateIconComponent {
  @Input() size: IconVariants['size'] = 'extra-large';
  @Input() class: string = undefined;

  iconVariants = iconVariants;
  twMerge = twMerge;
}
