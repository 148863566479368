<div [class.red]="isOverdue" data-test-class="wiedervorlage-icon">
  <ng-container *ngIf="isOverdue; else defaultFrist">
    <ozgcloud-svgicon
      svgIcon="resubmission_expired"
      data-test-class="wiedervorlage-icon-is-overdue"
    ></ozgcloud-svgicon>
  </ng-container>
  <ng-template #defaultFrist>
    <ozgcloud-icon icon="update" data-test-class="wiedervorlage-icon-default"></ozgcloud-icon>
  </ng-template>
</div>
