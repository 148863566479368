<mat-icon
  *ngIf="icon"
  data-test-class="icon"
  [class.with-text]="text"
  [style.visibility]="isDisabled ? 'hidden' : 'visible'"
>
  {{ icon }}
</mat-icon>

<mat-icon
  *ngIf="svgIcon"
  data-test-class="icon"
  [class.with-text]="text"
  [svgIcon]="svgIcon"
  [style.visibility]="isDisabled ? 'hidden' : 'visible'"
>
</mat-icon>

<span *ngIf="text" data-test-class="button-with-spinner-text">{{ text }}</span>

<ozgcloud-spinner [diameter]="22" padding="0" [stateResource]="stateResource" [show]="showSpinner">
</ozgcloud-spinner>
