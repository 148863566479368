<ng-container *ngIf="iconSizeBig; else standardSize">
  <ozgcloud-svgicon-big [svgIcon]="icon"></ozgcloud-svgicon-big>
</ng-container>
<ng-template #standardSize>
  <ozgcloud-svgicon [svgIcon]="icon"></ozgcloud-svgicon>
</ng-template>

<div>
  <div class="headline">{{ headline }}</div>
  <div class="text">{{ text }}</div>
  <ng-content></ng-content>
</div>
