import { Component, Input } from '@angular/core';
import { ApiRootLinkRel, ApiRootResource } from '@alfa-client/api-root-shared';
import { StateResource } from '@alfa-client/tech-shared';
import { hasLink } from '@ngxp/rest';

@Component({
  selector: 'alfa-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
})
export class HelpMenuComponent {
  @Input() apiRootStateResource: StateResource<ApiRootResource>;

  readonly apiRootLinkRel = ApiRootLinkRel;

  public getNumberOfMenuItems(): number {
    return [hasLink(this.apiRootStateResource?.resource, ApiRootLinkRel.DOCUMENTATIONS)].filter(
      (isTrue) => isTrue,
    ).length;
  }
}
