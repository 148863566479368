import { Pipe, PipeTransform } from '@angular/core';
import { hasLink, Resource } from '@ngxp/rest';
import { isEmpty } from 'lodash-es';

@Pipe({ name: 'hasAnyLink' })
export class HasAnyLinkPipe implements PipeTransform {
  transform(resource: Resource, ...links: string[]) {
    return !isEmpty(
      links.map((link) => hasLink(resource, link)).filter((hasLink) => hasLink === true),
    );
  }
}
