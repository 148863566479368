import { convertForDataTest, TechSharedModule } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { cva, VariantProps } from 'class-variance-authority';

const textareaVariants = cva(
  'block w-full rounded-lg border bg-background-50 px-3 py-2 text-base text-text leading-5 focus:border-primary focus:ring-primary outline-none',
  {
    variants: {
      variant: {
        default: 'border-primary-600/50',
        error: 'border-error',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
type TextareaVariants = VariantProps<typeof textareaVariants>;

@Component({
  selector: 'ods-textarea',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TechSharedModule],
  template: `
    <div class="mt-2">
      <label [for]="id" class="text-md mb-2 block font-medium text-text"
        >{{ label }}
        <ng-container *ngIf="required"><i aria-hidden="true">*</i></ng-container>
      </label>
      <textarea
        [id]="id"
        [formControl]="fieldControl"
        [rows]="rows"
        [ngClass]="textareaVariants({ variant })"
        [placeholder]="placeholder"
        [autocomplete]="autocomplete"
        [required]="required"
        [attr.aria-invalid]="variant === 'error'"
        [attr.data-test-id]="(label | convertForDataTest) + '-textarea'"
        #textAreaElement
      ></textarea>
      <ng-content select="[error]"></ng-content>
    </div>
  `,
})
export class TextareaComponent {
  @ViewChild('textAreaElement') textAreaElement: ElementRef;

  @Input({ required: true }) label!: string;
  @Input({ required: true }) placeholder!: string;
  @Input() error: string;
  @Input() rows: number = 3;
  @Input() autocomplete: string = 'off';
  @Input() variant: TextareaVariants['variant'];
  @Input() fieldControl: FormControl;
  @Input() required: boolean = false;

  @Input() set focus(value: boolean) {
    if (value && this.textAreaElement) {
      this.textAreaElement.nativeElement.focus();
    }
  }

  textareaVariants = textareaVariants;

  get id(): string {
    return convertForDataTest(this.label);
  }
}
