import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

import { IconVariants, iconVariants } from '../IconClasses';

@Component({
  selector: 'ods-stamp-icon',
  standalone: true,
  imports: [NgClass],
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-bewilligt', class)]"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      d="M2.742 21.213h-.409a.872.872 0 0 1-.63-.252.873.873 0 0 1-.253-.631v-3.333A2.557 2.557 0 0 1 4 14.447h4.667a.786.786 0 0 0 .783-.784v-.366c0-1.823-.434-3.522-1.315-4.907a4.388 4.388 0 0 1-.685-2.393c0-1.291.571-2.544 1.511-3.372a4.534 4.534 0 0 1 3.568-1.141c2.01.227 3.678 1.823 3.982 3.802a4.755 4.755 0 0 1-.527 2.973l-.012.02c-.945 1.575-1.422 3.212-1.422 4.818v.566c0 .43.353.784.783.784H20a2.557 2.557 0 0 1 2.55 2.55v3.333a.873.873 0 0 1-.253.63.873.873 0 0 1-.63.253h-.066a2.666 2.666 0 0 1-2.3 1.334H5.032a2.642 2.642 0 0 1-2.29-1.334Zm18.041-1.766v-2.45a.787.787 0 0 0-.783-.784h-4.667a2.557 2.557 0 0 1-2.55-2.55v-.566c0-1.925.555-3.817 1.635-5.7.307-.557.452-1.198.34-1.845-.195-1.196-1.196-2.17-2.415-2.308a2.8 2.8 0 0 0-1.949.51l-.246.202c-.606.496-.93 1.269-.93 2.04 0 .53.137 1.03.417 1.479 1.017 1.648 1.582 3.647 1.582 5.822v.366a2.557 2.557 0 0 1-2.55 2.55H4a.786.786 0 0 0-.783.784v2.45h17.566Z"
      clip-rule="evenodd"
    />
  </svg>`,
})
export class StampIconComponent {
  @Input() size: IconVariants['size'] = 'large';
  @Input() class: string = undefined;

  iconVariants = iconVariants;
  twMerge = twMerge;
}
