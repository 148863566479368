/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { EMPTY_STRING } from '@alfa-client/tech-shared';

/**
 * Workaround: MatTooltipModule aus Angular 13 schafft es nicht immer,
 * das Tooltip-HTML aus dem DOM zu löschen, wenn die Maus das entsprechende
 * Element verlässt. Diese Directiven implementieren Tooltips auf Basis
 * des HTML-Attributes title. Sie können als Ersatz für MatTooltipModule
 * verwendet werden.
 *
 * Implementiert sind nur die Directiven, die bei uns zum Einsatz kommen.
 */

const titleAttribute: string = 'title';
const ariaDescribedBy: string = 'aria-describedby';

@Directive({
  selector: '[matTooltip]',
})
export class MatTooltipDirective implements OnChanges {
  @Input() matTooltip: string = EMPTY_STRING;

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    this.el.nativeElement.setAttribute(titleAttribute, this.matTooltip);
    this.el.nativeElement.setAttribute(ariaDescribedBy, this.matTooltip);
  }
}

@Directive({
  selector: '[matTooltipDisabled]',
})
export class MatTooltipDisabledDirective implements OnInit {
  @Input() matTooltipDisabled: boolean = false;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (this.matTooltipDisabled) {
      this.el.nativeElement.setAttribute(titleAttribute, EMPTY_STRING);
      this.el.nativeElement.setAttribute(ariaDescribedBy, EMPTY_STRING);
    }
  }
}

@Directive({
  selector: '[matTooltipClass]',
})
export class MatTooltipClassDirective {
  @Input() matTooltipClass: string = EMPTY_STRING;
}
