import { Injectable } from '@angular/core';
import { SnackBarService } from '@alfa-client/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { of } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { COMMAND_ERROR_MESSAGES, CREATE_COMMAND_MESSAGE_BY_ORDER } from '../command.message';
import { CommandListResource, CommandResource, CreateCommandProps } from '../command.model';
import { CommandRepository } from '../command.repository';
import { hasCommandError, isConcurrentModification, isPending, isRevokeable } from '../command.util';
import {
  CommandProps,
  LoadCommandListProps,
  PollCommandProps,
  SnackBarProps,
  createCommand,
  createCommandFailure,
  createCommandSuccess,
  loadCommandList,
  pollCreatedCommand,
  pollRevokedCommand,
  publishConcurrentModificationAction,
  revokeCommand,
  revokeCommandFailure,
  revokeCommandSuccess,
  showRevokeSnackbar,
  showSnackbar,
} from './command.actions';
import { isEqual, isUndefined } from 'lodash-es';
import { EMPTY_STRING } from '@alfa-client/tech-shared';

@Injectable()
export class CommandEffects {
  static readonly POLL_DELAY: number = 500;

  constructor(
    private readonly actions$: Actions,
    private store$: Store,
    private readonly repository: CommandRepository,
    private snackbarService: SnackBarService,
  ) {}

  loadCommandList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCommandList),
      switchMap((props: LoadCommandListProps) =>
        this.repository.getPendingCommands(props.resource, props.linkRel).pipe(
          map((commandList: CommandListResource) => props.successAction(commandList)),
          catchError((error) => of(props.failureAction(error.error))),
        ),
      ),
    ),
  );

  createCommand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCommand),
      switchMap((props: CreateCommandProps) =>
        this.repository.createCommand(props.resource, props.linkRel, props.command).pipe(
          mergeMap((command: CommandResource) => this.handleCreatedCommand(props, command)),
          catchError((error) => of(createCommandFailure({ error, command: props.command }))),
        ),
      ),
    ),
  );

  pollCreatedCommand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollCreatedCommand),
      delay(CommandEffects.POLL_DELAY),
      switchMap((props: PollCommandProps) =>
        this.repository.getCommand(props.command).pipe(
          mergeMap((command: CommandResource) =>
            this.handleCreatedCommand(props.createCommandProps, command),
          ),
          catchError((error) => of(createCommandFailure({ command: props.command, error }))),
        ),
      ),
    ),
  );

  handleCreatedCommand(
    createCommandProps: CreateCommandProps,
    command: CommandResource,
  ): TypedAction<string>[] {
    if (isPending(command)) {
      return [pollCreatedCommand({ createCommandProps, command })];
    }
    return this.handleCreateCommandSuccess(createCommandProps, command);
  }

  handleCreateCommandSuccess(
    createCommandProps: CreateCommandProps,
    command: CommandResource,
  ): TypedAction<string>[] {
    if (isRevokeable(command)) {
      return [createCommandSuccess({ command }), showRevokeSnackbar({ command })];
    }
    if (hasCommandError(command) && isConcurrentModification(command.errorMessage)) {
      this.showError(command);
      return [createCommandSuccess({ command }), publishConcurrentModificationAction()];
    }
    return [createCommandSuccess({ command }), showSnackbar({ createCommandProps, command })];
  }

  private showError(command: CommandResource): void {
    this.snackbarService.showError(COMMAND_ERROR_MESSAGES[command.errorMessage]);
  }

  showRevokeSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showRevokeSnackbar),
        tap((props: CommandProps) =>
          this.snackbarService.show(
            props.command,
            CREATE_COMMAND_MESSAGE_BY_ORDER[props.command.order],
            this.buildRevokeFunction(props.command),
          ),
        ),
      ),
    { dispatch: false },
  );

  buildRevokeFunction(command: CommandResource) {
    return () => this.store$.dispatch(revokeCommand({ command }));
  }

  showSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showSnackbar),
        tap((props: SnackBarProps) => {
          if (!isEqual(props.createCommandProps.snackBarMessage, EMPTY_STRING)) {
            this.snackbarService.show(props.command, this.getSnackBarMessage(props));
          }
        }),
      ),
    { dispatch: false },
  );

  private getSnackBarMessage(props: SnackBarProps): string {
    return isUndefined(props.createCommandProps.snackBarMessage) ?
        CREATE_COMMAND_MESSAGE_BY_ORDER[props.command.order]
      : props.createCommandProps.snackBarMessage;
  }

  revokeCommand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(revokeCommand),
      switchMap((props: CommandProps) =>
        this.repository.revokeCommand(props.command).pipe(
          mergeMap((command: CommandResource) => this.handleRevokeCommand(command)),
          catchError((error) => of(revokeCommandFailure({ command: props.command, error }))),
        ),
      ),
    ),
  );

  pollRevokedCommand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollRevokedCommand),
      delay(CommandEffects.POLL_DELAY),
      switchMap((props: PollCommandProps) =>
        this.repository.getCommand(props.command).pipe(
          mergeMap((command: CommandResource) => this.handleRevokeCommand(command)),
          catchError((error) => of(revokeCommandFailure({ command: props.command, error }))),
        ),
      ),
    ),
  );

  handleRevokeCommand(command: CommandResource): (CommandProps & TypedAction<string>)[] {
    if (isPending(command)) {
      return [pollRevokedCommand({ command })];
    }
    return [revokeCommandSuccess({ command })];
  }
}
