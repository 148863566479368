import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VariantProps, cva } from 'class-variance-authority';

import { SpinnerIconComponent } from '../icons/spinner-icon/spinner-icon.component';

export const buttonVariants = cva(
  'flex cursor-pointer items-center gap-4 rounded-md font-medium disabled:cursor-wait text-sm min-w-32 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ozgblue-800',
  {
    variants: {
      variant: {
        primary: 'hover:enabled:bg-primary-hover bg-primary text-white shadow-sm',
        outline:
          'border border-primary bg-background-50 text-primary hover:enabled:bg-background-100',
      },
      size: {
        medium: 'h-9 py-2 px-4',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'medium',
    },
  },
);
type ButtonVariants = VariantProps<typeof buttonVariants>;

@Component({
  selector: 'ods-button',
  standalone: true,
  imports: [CommonModule, SpinnerIconComponent],
  template: `<button
    type="button"
    [ngClass]="buttonVariants({ size, variant })"
    [disabled]="isLoading"
    [attr.aria-disabled]="isLoading"
    [attr.aria-label]="text"
    [attr.data-test-id]="dataTestId"
    (click)="clickEmitter.emit()"
  >
    <ng-content *ngIf="!isLoading" select="[icon]"></ng-content>
    <ods-spinner-icon *ngIf="isLoading" size="medium"></ods-spinner-icon>
    <div class="flex-grow">{{ text }}</div>
  </button>`,
})
export class ButtonComponent {
  @Input() text: string = '';
  @Input() dataTestId: string = '';
  @Input() isLoading: boolean = false;
  @Input() variant: ButtonVariants['variant'];
  @Input() size: ButtonVariants['size'];

  @Output() public clickEmitter: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  buttonVariants = buttonVariants;
}
