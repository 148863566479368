import { convertForDataTest, TechSharedModule } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { cva, VariantProps } from 'class-variance-authority';
import { ErrorMessageComponent } from '../error-message/error-message.component';

const textInputVariants = cva(
  'block w-full rounded-lg border bg-background-50 px-3 py-2 text-base leading-5 text-text focus:border-primary focus:ring-primary outline-none',
  {
    variants: {
      variant: {
        default: 'border-primary-600/50',
        error: 'border-error',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
type TextInputVariants = VariantProps<typeof textInputVariants>;

@Component({
  selector: 'ods-text-input',
  standalone: true,
  imports: [CommonModule, ErrorMessageComponent, ReactiveFormsModule, TechSharedModule],
  template: `
    <div>
      <label [for]="id" class="text-md mb-2 block font-medium text-text"
        >{{ label }}
        <ng-container *ngIf="required"><i aria-hidden="true">*</i></ng-container>
      </label>
      <div class="mt-2">
        <input
          type="text"
          [id]="id"
          [formControl]="fieldControl"
          [ngClass]="textInputVariants({ variant })"
          [placeholder]="placeholder"
          [autocomplete]="autocomplete"
          [required]="required"
          [attr.aria-invalid]="variant === 'error'"
          [attr.data-test-id]="(label | convertForDataTest) + '-text-input'"
          #inputElement
        />
      </div>
      <ng-content select="[error]"></ng-content>
    </div>
  `,
})
export class TextInputComponent {
  @ViewChild('inputElement') inputElement: ElementRef;

  @Input({ required: true }) label: string;
  @Input() placeholder: string = '';
  @Input() autocomplete: string = 'off';
  @Input() variant: TextInputVariants['variant'];
  @Input() fieldControl: FormControl;
  @Input() required: boolean = false;

  @Input() set focus(value: boolean) {
    if (value && this.inputElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  textInputVariants = textInputVariants;

  get id(): string {
    return convertForDataTest(this.label);
  }
}
