import { Resource } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { StateResource } from './resource.util';

export interface ListResourceServiceConfig<B> {
  baseResource: Observable<StateResource<B>>;
  listLinkRel: LinkRelationName;
  listResourceListLinkRel: LinkRelationName;
  createLinkRel?: LinkRelationName;
}

export interface CreateResourceData<T> {
  resource: T;
  linkRel: string;
  toCreate: any;
}

export interface SaveResourceData<T> {
  resource: T;
  linkRel: string;
  toSave: any;
}

export interface ListItemResource extends Resource {}
export declare type LinkRelationName = string;

export interface ResourceServiceConfig<B> {
  resource: Observable<StateResource<B>>;
  getLinkRel: LinkRelationName;
  delete?: { linkRel: LinkRelationName; order?: string };
  edit?: { linkRel: LinkRelationName; order?: string };
}
