/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, OnInit } from '@angular/core';
import { StateResource } from '@alfa-client/tech-shared';
import { VorgangService, VorgangWithEingangResource } from '@alfa-client/vorgang-shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'alfa-vorgang-in-postfach-breadcrumb-container',
  templateUrl: './vorgang-in-postfach-breadcrumb-container.component.html',
  styleUrls: ['./vorgang-in-postfach-breadcrumb-container.component.scss'],
})
export class VorgangInPostfachBreadcrumbContainerComponent implements OnInit {
  vorgangStateResource$: Observable<StateResource<VorgangWithEingangResource>>;

  constructor(private vorgangService: VorgangService) {}

  ngOnInit(): void {
    this.vorgangStateResource$ = this.vorgangService.getVorgangWithEingang();
  }
}
