import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'ods-bescheid-container',
  standalone: true,
  imports: [CommonModule],
  template: ` <article class="bg-background-150 flex flex-col gap-4 rounded-lg p-4">
    <ng-content></ng-content>
  </article>`,
})
export class BescheidContainerComponent {}
